export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAIL_REQUEST = "PRODUCT_DETAIL_REQUEST";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAIL = "PRODUCT_DETAIL_FAIL";

export const PRODUCT_CATEGORY_REQUEST = "PRODUCT_CATEGORY_REQUEST";
export const PRODUCT_CATEGORY_SUCCESS = "PRODUCT_CATEGORY_SUCCESS";
export const PRODUCT_CATEGORY_FAIL = "PRODUCT_CATEGORY_FAIL";

export const PRODUCT_COLORS_REQUEST = "PRODUCT_COLORS_REQUEST";
export const PRODUCT_COLORS_SUCCESS = "PRODUCT_COLORS_SUCCESS";
export const PRODUCT_COLORS_REJECT = "PRODUCT_COLORS_REJECT";

export const RELATED_PRODUCTS_REQUEST = "RELATED_PRODUCTS_REQUEST";
export const RELATED_PRODUCTS_SUCCESS = "RELATED_PRODUCTS_SUCCESS";
export const RELATED_PRODUCTS_FAIL = "RELATED_PRODUCTS_FAIL";

export const GET_SEARCH_PRODUCTS_REQUEST = "GET_SEARCH_PRODUCTS_REQUEST";
export const GET_SEARCH_PRODUCTS_SUCCESS = "GET_SEARCH_PRODUCTS_SUCCESS";
export const GET_SEARCH_PRODUCTS_FAIL = "GET_SEARCH_PRODUCTS_FAIL";

export const CLEAR_SEARCHED_PRODUCTS = "CLEAR_SEARCHED_PRODUCTS";

export const REVIEW_ELIGIBLE_REQUEST = "REVIEW_ELIGIBLE_REQUEST";

export const REVIEW_ELIGIBLE_SUCCESS = "REVIEW_ELIGIBLE_SUCCESS";

export const REVIEW_ELIGIBLE_FAIL = "REVIEW_ELIGIBLE_FAIL";

export const REVIEW_CREATE_REQUEST = "REVIEW_CREATE_REQUEST";

export const REVIEW_CREATE_SUCCESS = "REVIEW_CREATE_SUCCESS";

export const REVIEW_CREATE_FAIL = "REVIEW_CREATE_FAIL";

export const GET_SUBCATEGORIES_FAIL = "GET_SUBCATEGORIES_FAIL";

export const GET_SUBCATEGORIES_REQUEST = "GET_SUBCATEGORIES_REQUEST";

export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS";

export const PRODUCT_RECOMMENDED_FAIL="PRODUCT_RECOMMENDED_FAIL"
export const PRODUCT_RECOMMENDED_SUCCESS="PRODUCT_RECOMMENDED_SUCCESS"
export const PRODUCT_RECOMMENDED_REQUEST="PRODUCT_RECOMMENDED_REQUEST"

