export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS='USER_LOGOUT_SUCCESS'


export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'
export const USER_DETAIL_FAIL = 'USER_DETAIL_FAIL'
export const USER_DETAIL_RESET = 'USER_DETAIL_RESET'


export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'


export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

// userConstants.js
export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';

export const USER_ADD_FAVOURITES_REQUEST = 'USER_ADD_FAVOURITES_REQUEST';
export const USER_ADD_FAVOURITES_SUCCESS = 'USER_ADD_FAVOURITES_SUCCESS';
export const USER_ADD_FAVOURITES_FAIL = 'USER_ADD_FAVOURITES_FAIL';

export const USER_GET_FAVOURITES_REQUEST = 'USER_GET_FAVOURITES_REQUEST';
export const USER_GET_FAVOURITES_SUCCESS = 'USER_GET_FAVOURITES_SUCCESS';
export const USER_GET_FAVOURITES_FAIL = 'USER_GET_FAVOURITES_FAIL';

export const USER_REMOVE_FAVOURITE_REQUEST = 'USER_REMOVE_FAVOURITE_REQUEST';
export const USER_REMOVE_FAVOURITE_SUCCESS = 'USER_REMOVE_FAVOURITE_SUCCESS';
export const USER_REMOVE_FAVOURITE_FAIL = 'USER_REMOVE_FAVOURITE_FAIL';

export const LOGIN_WITH_OAUTH_LOADING = 'LOGIN_WITH_OAUTH_LOADING';
export const LOGIN_WITH_OAUTH_SUCCESS = 'LOGIN_WITH_OAUTH_SUCCESS';
export const LOGIN_WITH_OAUTH_FAIL = 'LOGIN_WITH_OAUTH_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGIN_WITH_EMAIL_LOADING = 'LOGIN_WITH_EMAIL_LOADING';
export const LOGIN_WITH_EMAIL_SUCCESS = 'LOGIN_WITH_EMAIL_SUCCESS';
export const LOGIN_WITH_EMAIL_FAIL = 'LOGIN_WITH_EMAIL_FAIL';

export const REGISTER_WITH_EMAIL_LOADING = 'REGISTER_WITH_EMAIL_LOADING';
export const REGISTER_WITH_EMAIL_SUCCESS = 'REGISTER_WITH_EMAIL_SUCCESS';
export const REGISTER_WITH_EMAIL_FAIL = 'REGISTER_WITH_EMAIL_FAIL';

export const USER_LOADING = 'USER_LOADING';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'USER_FAIL';
