import React from 'react'

const Loader = () => {
  return (
    
    <div className="spinner"></div>

  )
}

export default Loader