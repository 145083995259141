export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR';
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_REQUEST = 'REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_ERROR = 'REMOVE_FROM_CART_ERROR';
export const GET_MY_CART_ERROR='GET_MY_CART_ERROR'
export const GET_MY_CART_REQUEST='GET_MY_CART_REQUEST'
export const GET_MY_CART_SUCCESS='GET_MY_CART_SUCCESS'
export const UPDATE_CART_QTY_REQUEST='UPDATE_CART_QTY_REQUEST'
export const UPDATE_CART_QTY_SUCCESS='UPDATE_CART_QTY_SUCCESS'
export const UPDATE_CART_QTY_FAIL='UPDATE_CART_QTY_FAIL'
export const CLEAR_MY_CART_REQUEST='CLEAR_MY_CART_REQUEST'
export const CLEAR_MY_CART_SUCCESS='CLEAR_MY_CART_SUCCESS'
export const CLEAR_MY_CART_FAIL='CLEAR_MY_CART_FAIL'