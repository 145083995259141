// orderConstants.js
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';



export const ORDER_CANCEL_REQUEST='ORDER_CANCEL_REQUEST'
export const ORDER_CANCEL_SUCCESS='ORDER_CANCEL_SUCCESS'
export const ORDER_CANCEL_FAIL='ORDER_CANCEL_FAIL'

export const COUPON_VALIDATE_REQUEST='COUPON_VALIDATE_REQUEST'
export const COUPON_VALIDATE_SUCCESS='COUPON_VALIDATE_SUCCESS'
export const COUPON_VALIDATE_FAIL='COUPON_VALIDATE_FAIL'

export const ORDERS_MY_REQUEST='ORDERS_MY_REQUEST'
export const ORDERS_MY_SUCCESS='ORDERS_MY_SUCCESS'
export const ORDERS_MY_FAIL='ORDERS_MY_FAIL'

export const ORDER_DETAIL_REQUEST='ORDERS_DETAIL_REQUEST'
export const ORDER_DETAIL_SUCCESS='ORDERS_DETAIL_SUCCESS'
export const ORDER_DETAIL_FAIL='ORDERS_DETAIL_FAIL'


export const ORDER_UPDATE_REQUEST='ORDERS_UPDATE_REQUEST'
export const ORDER_UPDATE_SUCCESS='ORDERS_UPDATE_SUCCESS'
export const ORDER_UPDATE_FAIL='ORDERS_UPDATE_FAIL'

export const CHECK_IF_FIRST_ORDER_REQUEST='CHECK_IF_FIRST_ORDER_REQUEST'
export const CHECK_IF_FIRST_ORDER_SUCCESS='CHECK_IF_FIRST_ORDER_SUCCESS'
export const CHECK_IF_FIRST_ORDER_FAIL='CHECK_IF_FIRST_ORDER_FAIL'